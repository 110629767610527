export const options = [
  { name: 'english', value: 'en' },
  { name: 'polish', value: 'pl' },
  { name: 'spanish', value: 'es' },
  { name: 'french', value: 'fr' },
  { name: 'german', value: 'de' },
  { name: 'portuguese', value: 'pt' },
  { name: 'arabic (UAE)', value: 'ar-ae' },
  { name: 'bengali', value: 'bn' },
  { name: 'chinese', value: 'cn' },
  { name: 'czech', value: 'cs' },
  { name: 'danish', value: 'da' },
  { name: 'finnish', value: 'fi' },
  { name: 'greek', value: 'gr' },
  { name: 'hebrew', value: 'he' },
  { name: 'hindi', value: 'hi' },
  { name: 'hungarian', value: 'hu' },
  { name: 'indonesian', value: 'id' },
  { name: 'italian', value: 'it' },
  { name: 'japanese', value: 'jp' },
  { name: 'korean', value: 'kr' },
  { name: 'marathi', value: 'mr' },
  { name: 'dutch', value: 'nl' },
  { name: 'norwegian', value: 'no' },
  { name: 'brazilian portuguese', value: 'pt-br' },
  { name: 'romanian', value: 'ro' },
  { name: 'russian', value: 'ru' },
  { name: 'swedish', value: 'sv' },
  { name: 'tamil', value: 'ta' },
  { name: 'telugu', value: 'te' },
  { name: 'thai', value: 'th' },
  { name: 'turkish', value: 'tr' },
  { name: 'urdu', value: 'ur' },
  { name: 'vietnamese', value: 'vi' },
  { name: 'welsh', value: 'cy' },
];

export const localeImports = {
  en: () => import('locales/en.json'),
  pl: () => import('locales/pl.json'),
  es: () => import('locales/es.json'),
  fr: () => import('locales/fr.json'),
  de: () => import('locales/de.json'),
  pt: () => import('locales/pt.json'),
  'ar-ae': () => import('locales/ar-ae.json'),
  bn: () => import('locales/bn.json'),
  cn: () => import('locales/cn.json'),
  cs: () => import('locales/cs.json'),
  da: () => import('locales/da.json'),
  fi: () => import('locales/fi.json'),
  gr: () => import('locales/gr.json'),
  he: () => import('locales/he.json'),
  hi: () => import('locales/hi.json'),
  hu: () => import('locales/hu.json'),
  id: () => import('locales/id.json'),
  it: () => import('locales/it.json'),
  jp: () => import('locales/jp.json'),
  kr: () => import('locales/kr.json'),
  mr: () => import('locales/mr.json'),
  nl: () => import('locales/nl.json'),
  no: () => import('locales/no.json'),
  'pt-br': () => import('locales/pt-br.json'),
  ro: () => import('locales/ro.json'),
  ru: () => import('locales/ru.json'),
  sv: () => import('locales/sv.json'),
  ta: () => import('locales/ta.json'),
  te: () => import('locales/te.json'),
  th: () => import('locales/th.json'),
  tr: () => import('locales/tr.json'),
  ur: () => import('locales/ur.json'),
  vi: () => import('locales/vi.json'),
  cy: () => import('locales/cy.json'),
};

export const revertedPositionLanguages = ['ar-ae', 'ur', 'he'];
